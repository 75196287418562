import React from 'react';
import logo from '../../../assets/images/fluxomta.webp';

function Sidebar({ currentStep }) {
    return (
        <div className="w-1/4 p-8 text-white rounded-l-lg bg-primary-800">
            <ul className="space-y-8">
                <img src={logo} alt="Fluxo MTA" />
                <li className="flex items-center space-x-2">
                    <div className={`flex items-center justify-center w-8 h-8 rounded-full ${currentStep === 1 ? 'bg-primary-400 text-white' : 'bg-white text-primary-800'}`}>
                        1
                    </div>
                    <span className={`${currentStep === 1 ? 'font-bold' : ''}`}>Sobre Você</span>
                </li>
                <li className="flex items-center space-x-2">
                    <div className={`flex items-center justify-center w-8 h-8 rounded-full ${currentStep === 2 ? 'bg-primary-400 text-white' : 'bg-white text-primary-800'}`}>
                        2
                    </div>
                    <span className={`${currentStep === 2 ? 'font-bold' : ''}`}>Objetivos</span>
                </li>
                <li className="flex items-center space-x-2">
                    <div className={`flex items-center justify-center w-8 h-8 rounded-full ${currentStep === 3 ? 'bg-primary-400 text-white' : 'bg-white text-primary-800'}`}>
                        3
                    </div>
                    <span className={`${currentStep === 3 ? 'font-bold' : ''}`}>Alinhamento</span>
                </li>
                <li className="flex items-center space-x-2">
                    <div className={`flex items-center justify-center w-8 h-8 rounded-full ${currentStep === 4 ? 'bg-primary-400 text-white' : 'bg-white text-primary-800'}`}>
                        4
                    </div>
                    <span className={`${currentStep === 4 ? 'font-bold' : ''}`}>Step 04</span>
                </li>
                <li className="flex items-center space-x-2">
                    <div className={`flex items-center justify-center w-8 h-8 rounded-full ${currentStep === 5 ? 'bg-primary-400 text-white' : 'bg-white text-primary-800'}`}>
                        4
                    </div>
                    <span className={`${currentStep === 5 ? 'font-bold' : ''}`}>Step 05</span>
                </li>
            </ul>
        </div>
    );
}

export default Sidebar;
