import React from 'react';
import MultiStepForm from "../../Components/Store/Form/MultiStepForm";

const AssinePage = () => {
    return (
        <MultiStepForm />
    );
};

export default AssinePage;
