import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';

const LoginPage = () => {
    const { setUser } = useContext(UserContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || '/';

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_CUSTOM_API_URL}/v1/user-data`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const userData = response.data;
            setUser(userData);
            setMessage('Login realizado com sucesso!');
            navigate(from, { replace: true });
        } catch (error) {
            console.error('Erro ao buscar dados do usuário:', error);
            setMessage('Erro ao buscar os dados do usuário.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_CUSTOM_API_URL}/v1/login`, {
                username,
                password,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const token = response.data.token;

            if (token) {
                // Armazena o token no localStorage
                localStorage.setItem('token', token);

                // Busca os dados do usuário usando o token
                fetchUserData(token);
            } else {
                setMessage('Erro no login, token não recebido.');
            }

        } catch (error) {
            console.error('Erro durante o login:', error);
            setMessage('Login inválido. Por favor, revise os dados.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full p-8 bg-white rounded">
            {message && <div className="mb-4 text-red-500">{message}</div>}
            <div className="mb-4">
                <label htmlFor="username" className="label">Nome de Usuário ou E-mail</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="form-input"
                    required
                />
            </div>
            <div className="mb-6">
                <label htmlFor="password" className="label">Senha</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-input"
                    required
                />
            </div>
            <button type="submit" className="btn-submit" disabled={loading}>
                {loading ? 'Entrando...' : 'Entrar'}
            </button>
        </form>
    );
};

export default LoginPage;
