import React from 'react';
import CardMacro from './CardMacro';
import CardPressao from './CardPressao';
import CardVies from './CardVies';
import CardSaldo from './CardSaldo';
import SaldoVariation from './SaldoVariation';
import CardFooter from './CardFooter';
import CardHeadTicker from './CardHeadTicker';
import CardEficiencia from './CardEficiencia';

const CardTicker = ({
    ticker,
    pressao_comprador,
    pressao_vendedor,
    pts_variation,
    range_negativo,
    range_positivo,
    balance,
    saldo_atual,
    timestamp,
    variation,
    vies,
    variation_saldo,
    eficiencia,
    lado_eficiencia
}) => {
    return (
        <>
            <CardHeadTicker ticker={ticker} variation={variation} variationpts={pts_variation} />
            <div className='grid grid-cols-1 gap-4 mb-4 lg:grid-cols-2'>
                <div className='p-2 mt-4 border border-gray-200 rounded-md bg-gray-50'>
                    <span className="pl-1 font-semibold card-label">Day Trade</span>
                    <CardPressao pressao_comprador={pressao_comprador} pressao_vendedor={pressao_vendedor} />
                    <CardSaldo saldo_atual={saldo_atual} />
                    <SaldoVariation balance={balance} variation_saldo={variation_saldo} />
                    <CardEficiencia eficiencia={eficiencia} eficiencia_lado={lado_eficiencia} />
                </div>
                <div className='p-2 mt-4 border border-gray-200 rounded-md bg-gray-50'>
                    <span className="pl-1 font-semibold card-label">Longo Prazo</span>
                    <CardMacro range_negativo={range_negativo} range_positivo={range_positivo} />
                    <CardVies vies={vies} />
                </div>
            </div>
            <div className='absolute bottom-0 right-0 h-10 px-4'>
                <CardFooter timestamp={timestamp} />
            </div>
        </>
    );
};

export default CardTicker;
