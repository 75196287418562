import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const EficienciaChart = ({ tickerSymbol }) => {
    const [chartData, setChartData] = useState({
        series: [
            {
                name: 'Eficiência',
                type: 'line',
                data: []
            },
            {
                name: 'Média Móvel',
                type: 'line',
                data: []
            }
        ],
        options: {
            chart: {
                height: 400,
                type: 'line',  // Mantendo como 'line' já que se refere à eficiência
                animations: {
                    enabled: true
                },
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            fill: {
                type: 'solid',
                opacity: 0.7
            },
            xaxis: {
                type: 'datetime',
                categories: [],
                labels: {
                    formatter: (val) => {
                        const date = new Date(val);
                        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                    }
                }
            },
            yaxis: {
                title: {
                    text: 'Eficiência'
                },
                min: 0,
                max: 0,
                labels: {
                    formatter: (val) => val !== null ? val.toFixed(2) : ''
                }
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                }
            },
            legend: {
                show: true
            },
            annotations: {
                yaxis: [
                    {
                        y: 1,
                        borderColor: '#000',
                        label: {
                            borderColor: '#000',
                            style: {
                                color: '#fff',
                                background: '#000'
                            },
                            text: '1'
                        }
                    }
                ]
            }
        }
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!tickerSymbol) {
                console.error('tickerSymbol is null');
                return;
            }

            try {
                const response = await fetch(`https://api.server.fluxomta.com/market_data/eficiencia_mm32/${tickerSymbol}`);
                const data = await response.json();

                if (!Array.isArray(data.eficiencia_mm32)) {
                    console.error('Invalid data format for eficiencia:', data);
                    return;
                }

                const latestData = data.eficiencia_mm32.reduce((max, item) => (item.id > max.id ? item : max), data.eficiencia_mm32[0]);
                const latestDate = latestData.timestamp.split('T')[0];

                const filteredData = data.eficiencia_mm32.filter(item => item.timestamp.startsWith(latestDate));

                if (filteredData.length === 0) {
                    console.error('No data available for the last day:', latestDate);
                    setLoading(false);
                    return;
                }

                filteredData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                const eficienciaAtual = filteredData.map(item => item.eficiencia);
                const timestamps = filteredData.map(item => item.timestamp);

                // Determine minY and maxY to ensure zero line is centered
                const minY = Math.min(...eficienciaAtual);
                const maxY = Math.max(...eficienciaAtual);
                const padding = Math.max(Math.abs(minY), Math.abs(maxY)) * 0.1;

                const newSeriesEf = filteredData.map(item => ({
                    x: new Date(item.timestamp),
                    y: item.eficiencia
                }));

                const newSeriesMM32 = filteredData.map(item => ({
                    x: new Date(item.timestamp),
                    y: item.eficiencia_mm32
                }));

                setChartData(prevChartData => ({
                    series: [
                        {
                            name: 'Eficiência',
                            type: 'line',
                            data: newSeriesEf,
                            color: '#1f1f1f'
                        },
                        {
                            name: 'Média Móvel',
                            type: 'line',
                            data: newSeriesMM32,
                            color: '#FF5733'
                        }
                    ],
                    options: {
                        ...prevChartData.options,
                        xaxis: {
                            ...prevChartData.options.xaxis,
                            categories: timestamps
                        },
                        yaxis: {
                            ...prevChartData.options.yaxis,
                            min: minY > 0 ? 0 : minY - padding,
                            max: maxY < 0 ? 0 : maxY + padding,
                        }
                    }
                }));

                setLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [tickerSymbol]);

    return (
        <div>
            <div className={`card`}>
                <div className="flex items-center justify-between">
                    <span className="card-label">
                        Gráfico de Eficiência<span className="block text-xs font-normal">(Intraday)</span>
                    </span>
                </div>
            </div>
            {loading ? (
                <p>Carregando...</p>
            ) : (
                <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="line" // Usando 'line' para manter a consistência com as séries
                    height={chartData.options.chart.height}
                />
            )}
        </div>
    );
};

export default EficienciaChart;
