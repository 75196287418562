import React from 'react';

const CardHeadTicker = ({ ticker, variation, variationpts }) => {
    const variationClass = variation >= 0 ? 'buy-text' : 'sell-text';
    const safeToFixed = (value, decimals) => {
        return value !== undefined && value !== null ? value.toFixed(decimals) : '0.00';
    };

    return (
        <div className="flex">
            <div className="w-full">
                <div className="flex items-center justify-between border-b border-slate-200">
                    <h1 className="text-lg font-bold text-slate-700">Detalhes: {ticker}</h1>
                    <div className="flex items-center justify-end">
                        <div>
                            <span className={`mr-2 font-semibold text-xs ${variationClass}`}>{safeToFixed(variation, 2)}%</span>
                            <span className={`font-semibold text-xs ${variationClass}`}> {safeToFixed(variationpts, 2)} (pts)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardHeadTicker;