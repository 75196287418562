import React from 'react';
import logo from '../assets/images/fluxomta.webp';

const Spinner = () => {
    return (
        <div className="relative flex items-center justify-center h-[80vh]">
            <div className='flex items-center max-w-md gap-2 p-4 mx-auto rounded-full bg-primary-500'>
                <img src={logo} alt="Fluxo MTA" className='w-36' />
                <svg className="w-12 h-12 text-secondary-500 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
            </div>
        </div>
    );
};

export default Spinner;
