import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import Icons from '../../Components/Menu/Icons';

const EditAddress = () => {
    const { user, setUser } = useContext(UserContext);
    const [formData, setFormData] = useState({
        billing_address_1: '',
        billing_number: '',
        billing_address_2: '',
        billing_neighborhood: '',
        billing_city: '',
        billing_state: '',
        billing_postcode: '',
    });
    const [loadingForm, setLoadingForm] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    useEffect(() => {
        if (user) {
            setFormData({
                billing_address_1: user.billing?.address_1 || '',
                billing_number: user.billing?.number || '',
                billing_address_2: user.billing?.address_2 || '',
                billing_neighborhood: user.billing?.neighborhood || '',
                billing_city: user.billing?.city || '',
                billing_state: user.billing?.state || '',
                billing_postcode: user.billing?.postcode || '',
            });
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingForm(true);

        try {
            const updateData = {
                billing_address_1: formData.billing_address_1,
                billing_address_2: formData.billing_address_2,
                billing_city: formData.billing_city,
                billing_state: formData.billing_state,
                billing_postcode: formData.billing_postcode,
                billing_neighborhood: formData.billing_neighborhood,
                billing_number: formData.billing_number,
            };

            const response = await axios.post(`${process.env.REACT_APP_CUSTOM_API_URL}/v1/edit-address`, updateData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Atualizar o contexto com os novos dados
            const updatedUser = {
                ...user,
                billing: {
                    ...user.billing,
                    address_1: formData.billing_address_1,
                    address_2: formData.billing_address_2,
                    city: formData.billing_city,
                    state: formData.billing_state,
                    postcode: formData.billing_postcode,
                    number: formData.billing_number,
                    neighborhood: formData.billing_neighborhood,
                }
            };

            setUser(updatedUser);

            // Atualizar o token se um novo token for fornecido
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
            }

            setMessage('Endereço atualizado com sucesso!');
            setMessageType('success');
        } catch (error) {
            setMessage('Erro ao atualizar os dados. Por favor, tente novamente.');
            setMessageType('error');
            console.error('Erro ao atualizar os dados:', error);
        } finally {
            setLoadingForm(false);
        }

    };

    return (
        <div className="flex items-center justify-center">
            <form onSubmit={handleSubmit} className="w-full p-6 bg-white rounded">
                <h2 className="flex items-center mb-6 text-2xl font-bold">
                    <Icons.Map className="w-6 mr-2" />Editar Endereço
                </h2>
                {message && (
                    <div className={`mb-4 text-${messageType === 'success' ? 'green' : 'red'}-500`}>
                        {message}
                    </div>
                )}
                <div className="mb-4">
                    <label className="label">Endereço</label>
                    <input
                        type="text"
                        name="billing_address_1"
                        value={formData.billing_address_1}
                        onChange={handleChange}
                        className="form-input"
                    />
                </div>
                <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-3">
                    <div>
                        <label className="label">CEP</label>
                        <input
                            type="text"
                            name="billing_postcode"
                            value={formData.billing_postcode}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                    <div>
                        <label className="label">Número</label>
                        <input
                            type="text"
                            name="billing_number"
                            value={formData.billing_number}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                    <div>
                        <label className="label">Complemento</label>
                        <input
                            type="text"
                            name="billing_address_2"
                            value={formData.billing_address_2}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-3">
                    <div>
                        <label className="label">Estado</label>
                        <input
                            type="text"
                            name="billing_state"
                            value={formData.billing_state}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                    <div>
                        <label className="label">Cidade</label>
                        <input
                            type="text"
                            name="billing_city"
                            value={formData.billing_city}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                    <div>
                        <label className="label">Bairro</label>
                        <input
                            type="text"
                            name="billing_neighborhood"
                            value={formData.billing_neighborhood}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                </div>
                <button type="submit" className="btn-submit" disabled={loadingForm}>
                    {loadingForm ? (
                        <svg className="w-5 h-5 mr-3 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                    ) : 'Salvar'}
                </button>
            </form>
        </div>
    );
};

export default EditAddress;
