import React, { useContext, useEffect, useState, useCallback } from 'react';
import DataContext from '../../context/DataContext';
import { Helmet } from 'react-helmet';

import { Card } from '../../Components/Card/Index';
import CardSkeleton from '../../Components/Card/CardSkeleton';
import Icons from '../../Components/Icons';

const MaioresBaixas = () => {
    const { maioresBaixas, fetchMaioresBaixas, loadingBaixas } = useContext(DataContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetchingMore, setIsFetchingMore] = useState(false);

    const refreshData = useCallback(async (page = 1) => {
        await fetchMaioresBaixas(page);
    }, [fetchMaioresBaixas]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const handleLoadMore = async () => {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        setIsFetchingMore(true);
        await refreshData(nextPage);
        setIsFetchingMore(false);
    };

    return (
        <>
            <Helmet>
                <title>Maiores Baixas - Painel de Indicadores - Fluxo MTA</title>
                <meta name="description" content="This is the home page" />
            </Helmet>

            <div className="flex items-center mb-4 text-xl font-bold text-red-700">
                <span className='mr-2'>
                    <Icons.Low className="w-6" />
                </span>
                <h2 className="text-2xl">Maiores Baixas</h2>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                {loadingBaixas && currentPage === 1 ? (
                    Array.from({ length: 4 }).map((_, index) => (
                        <CardSkeleton key={index} />
                    ))
                ) : (
                    maioresBaixas.map((data, index) => (
                        <Card key={index} {...data} />
                    ))
                )}
            </div>
            {!loadingBaixas && maioresBaixas.length > 0 && (
                <div className="flex justify-center mt-4">
                    {isFetchingMore ? (
                        <button disabled type="button" className="text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2  dark:hover:bg-slate-700  inline-flex items-center">
                            carregando...
                        </button>
                    ) : (
                        <button
                            onClick={handleLoadMore}
                            className="px-4 py-2 text-white rounded-md bg-slate-600 hover:bg-slate-700"
                        >
                            Carregar mais
                        </button>
                    )}
                </div>
            )}
        </>
    );
};

export default MaioresBaixas;
