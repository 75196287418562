import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import MaskedInput from 'react-text-mask';
import Icons from '../../Components/Menu/Icons';

const EditAccount = () => {
    const { user, setUser } = useContext(UserContext);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        billing_persontype: '1',
        billing_cpf: '',
        billing_cnpj: '',
        company: '',
    });
    const [loadingForm, setLoadingForm] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    useEffect(() => {
        if (user) {
            setFormData({
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                email: user.email || '',
                billing_persontype: user.billing_persontype || '1',
                billing_cpf: user.billing_cpf || '',
                billing_cnpj: user.billing_cnpj || '',
                company: user.billing?.company || '',
            });
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingForm(true);

        try {
            // Construir os dados que serão enviados ao backend
            const updateData = {
                first_name: formData.first_name || user.first_name,
                last_name: formData.last_name || user.last_name,
                email: formData.email || user.email,
                billing_persontype: formData.billing_persontype || user.billing_persontype,
                billing_cpf: formData.billing_persontype === '1' ? (formData.billing_cpf || user.billing_cpf) : '',
                billing_cnpj: formData.billing_persontype === '2' ? (formData.billing_cnpj || user.billing_cnpj) : '',
                company: formData.billing_persontype === '2' ? (formData.company || user.billing.company) : '',
                billing_first_name: formData.first_name || user.billing.first_name,
                billing_last_name: formData.last_name || user.billing.last_name,
            };

            // Fazer a requisição POST para o endpoint customizado
            const response = await axios.post(`${process.env.REACT_APP_CUSTOM_API_URL}/v1/edit-account`, updateData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Atualizar o contexto do usuário com os dados retornados
            const updatedUser = {
                ...user,
                first_name: response.data.first_name || formData.first_name,
                last_name: response.data.last_name || formData.last_name,
                email: response.data.email || formData.email,
                billing_persontype: formData.billing_persontype,
                billing_cpf: formData.billing_cpf || user.billing_cpf,
                billing_cnpj: formData.billing_cnpj || user.billing_cnpj,
                billing: {
                    ...user.billing,
                    first_name: formData.first_name || user.billing.first_name,
                    last_name: formData.last_name || user.billing.last_name,
                    company: formData.company || user.billing.company,
                },
            };

            // Atualizar o token JWT se um novo token for retornado
            const newToken = response.data.token;
            if (newToken) {
                localStorage.setItem('token', newToken);
            }

            setUser(updatedUser);
            setMessage('Dados pessoais atualizados com sucesso!');
            setMessageType('success');
        } catch (error) {
            setMessage('Erro ao atualizar os dados pessoais. Por favor, tente novamente.');
            setMessageType('error');
            console.error('Erro ao atualizar os dados pessoais:', error);
        } finally {
            setLoadingForm(false);
        }
    };



    return (
        <div className="flex items-center justify-center">
            <form onSubmit={handleSubmit} className="w-full p-6 bg-white rounded">
                <h2 className="flex items-center mb-6 text-2xl font-bold">
                    <Icons.User className="w-6 mr-2" />Editar Dados Pessoais
                </h2>
                {message && (
                    <div className={`mb-4 text-${messageType === 'success' ? 'green' : 'red'}-500`}>
                        {message}
                    </div>
                )}
                <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                    <div>
                        <label className="label">Nome</label>
                        <input
                            type="text"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                    <div>
                        <label className="label">Sobrenome</label>
                        <input
                            type="text"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="label">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-input"
                    />
                </div>
                <div className="mb-4">
                    <label className="label">Tipo de Conta</label>
                    <select
                        name="billing_persontype"
                        value={formData.billing_persontype}
                        onChange={handleChange}
                        className="form-input"
                    >
                        <option value="1">Pessoa Física</option>
                        <option value="2">Pessoa Jurídica</option>
                    </select>
                </div>
                {formData.billing_persontype === '1' && (
                    <div className="mb-4">
                        <label className="label">CPF</label>
                        <MaskedInput
                            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                            name="billing_cpf"
                            value={formData.billing_cpf}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </div>
                )}
                {formData.billing_persontype === '2' && (
                    <>
                        <div className="mb-4">
                            <label className="label">CNPJ</label>
                            <MaskedInput
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                name="billing_cnpj"
                                value={formData.billing_cnpj}
                                onChange={handleChange}
                                className="form-input"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="label">Empresa</label>
                            <input
                                type="text"
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                                className="form-input"
                            />
                        </div>
                    </>
                )}
                <button type="submit" className="btn-submit" disabled={loadingForm}>
                    {loadingForm ? (
                        <svg className="w-5 h-5 mr-3 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                    ) : 'Salvar Dados Pessoais'}
                </button>
            </form>
        </div>
    );
};

export default EditAccount;
