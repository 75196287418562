import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

const UserMenu = ({ toggleMenu }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { user, logout, loading: userLoading } = useContext(UserContext);
    const navigate = useNavigate();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        toggleMenu && toggleMenu();
        setIsOpen(false);
    };

    const handleLogoutAndLinkClickMobile = () => {
        logout();
        navigate('/');
        handleLinkClick();
    };
    const handleLogoutAndLinkClickDesk = () => {
        logout();
        navigate('/');
    };

    if (userLoading) {
        return (
            <></>
        );
    }

    return (
        <div className="relative w-full text-sm text-slate-500" ref={menuRef}>
            {user ? (
                <>
                    <div className='block lg:hidden'>
                        <div className="flex items-center justify-start w-auto p-2 font-bold text-white transition-all rounded cursor-pointer lg:justify-end " onClick={() => setIsOpen(!isOpen)}>
                            <span className="flex">
                                Bem vindo, {user.first_name}!
                            </span>
                        </div>
                        {isOpen && (
                            <div className="absolute right-0 z-50 w-56 mt-2 origin-top-right bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1" role="none">
                                    <div className="px-4 py-2 text-sm text-gray-200 border-b border-gray-600">
                                        <p className="font-semibold">{user.first_name}</p>
                                        <p>{user.email}</p>
                                    </div>
                                    <Link to="/minha-conta/" className="flex items-center w-full px-4 py-2 text-sm text-gray-200 border-b border-gray-600 hover:bg-gray-700" onClick={handleLinkClick}>
                                        Minha Conta
                                    </Link>
                                    <Link to="/minha-conta/downloads" className="flex items-center w-full px-4 py-2 text-sm text-gray-200 border-b border-gray-600 hover:bg-gray-700">
                                        Downloads
                                    </Link>

                                    <button onClick={handleLogoutAndLinkClickMobile} className="flex items-center w-full px-4 py-2 text-sm text-gray-200 hover:bg-gray-700">
                                        Sair
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='hidden lg:block '>
                        <div className="flex items-center justify-start w-auto p-2 font-bold text-white transition-all rounded cursor-pointer lg:justify-end " onClick={() => setIsOpen(!isOpen)}>
                            <span className="flex items-center ">
                                <img className="hidden w-8 mr-2 rounded-full" src={user.avatar_url} alt="Avatar" /> Bem vindo, {user.first_name}!
                            </span>
                        </div>
                        {isOpen && (
                            <div className="absolute right-0 z-50 w-56 mt-2 origin-top-right bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1" role="none">
                                    <div className="px-4 py-2 text-sm text-gray-200 border-b border-gray-600">
                                        <div className='flex items-center'>
                                            <img className="hidden w-8 mr-2 rounded-full" src={user.avatar_url} alt="Avatar" />
                                            <p>{user.first_name} {user.last_name}</p>
                                        </div>
                                    </div>
                                    <Link to="/minha-conta/" className="flex items-center w-full px-4 py-2 text-sm text-gray-200 border-b border-gray-600 hover:bg-gray-700">
                                        Minha Conta
                                    </Link>
                                    <Link to="/minha-conta/downloads" className="flex items-center w-full px-4 py-2 text-sm text-gray-200 border-b border-gray-600 hover:bg-gray-700">
                                        Downloads
                                    </Link>

                                    <button onClick={handleLogoutAndLinkClickDesk} className="flex items-center w-full px-4 py-2 text-sm text-gray-200 hover:bg-gray-700">
                                        Sair
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className='flex justify-end gap-2'>
                    <Link to="/login" className="w-4/12">
                        <div className="!bg-primary-400 btn-submit !text-white hover:!bg-primary-800 hover:!text-secondary-500">
                            <span>Login</span>
                        </div>
                    </Link>
                    <Link to="/assine" className="w-4/12">
                        <div className="btn-submit">
                            <span className='w-full text-center '>Assine agora</span>
                        </div>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default UserMenu;
