import React from 'react';

const CardSaldo = ({ saldo_atual }) => {
    const formatCurrency = (value) => {
        if (isNaN(value)) return 'R$ 0,00';

        const absValue = Math.abs(value);
        const isNegative = value < 0;
        let formattedValue;

        if (absValue >= 1e9) {
            formattedValue = `${(absValue / 1e9).toFixed(2).replace('.', '.')}<span className="text-xs ml-0.5">Bi</span>`;
        } else if (absValue >= 1e6) {
            formattedValue = `${(absValue / 1e6).toFixed(2).replace('.', '.')}<span className="text-xs ml-0.5">Mi</span>`;
        } else if (absValue >= 1e3) {
            formattedValue = `${(absValue / 1e3).toFixed(0).replace('.', ',')}<span className="text-xs ml-0.5">Mil</span>`;
        } else {
            formattedValue = absValue.toFixed(2).replace('.', ',');
        }

        return `${isNegative ? '-' : ''}${formattedValue}`;
    };

    const saldoClass = saldo_atual < 0 ? 'sell' : 'buy';

    return (
        <div className="card">
            <div className="flex items-center justify-between">
                <span className="card-label">Saldo Atual</span>
                <span className={`card-label ${saldoClass}-text`} dangerouslySetInnerHTML={{ __html: formatCurrency(saldo_atual) }}></span>
            </div>
        </div>
    );
};

export default CardSaldo;
