import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import EditAddress from './EditAddress';
import EditAccount from './EditAccountPage';
import ChangePassword from './EditPassword';
import DownloadPage from './Downloads';
import Icons from '../../Components/Menu/Icons';


const MyAccount = () => {
    const [activeTab, setActiveTab] = useState('edit-account');
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split('/').pop();
        switch (path) {
            case 'endereco':
                setActiveTab('edit-address');
                break;
            case 'senha':
                setActiveTab('edit-password');
                break;
            case 'downloads':
                setActiveTab('downloads');
                break;
            case 'editar-conta':
            default:
                setActiveTab('edit-account');
                break;
        }
    }, [location]);

    return (
        <div className="max-w-4xl mx-auto my-8">
            <div className="grid grid-cols-3 border-b border-gray-200">
                <button
                    className={`px-4 py-2 font-semibold text-gray-700 rounded-t-lg hover:bg-primary-300 hover:text-white ${activeTab === 'edit-account' ? 'text-white border-b-2 border-primary-300 bg-primary-300' : ''
                        }`}
                    onClick={() => setActiveTab('edit-account')}
                >
                    <div className='flex items-center justify-center'><Icons.User className="w-6 mr-2" /> Dados Pessoais </div>
                </button>
                <button
                    className={`ml-4 px-4 py-2 font-semibold text-gray-700 rounded-t-lg hover:bg-primary-300 hover:text-white ${activeTab === 'edit-address' ? 'text-white border-b-2 border-primary-300 bg-primary-300' : ''
                        }`}
                    onClick={() => setActiveTab('edit-address')}
                >
                    <div className='flex items-center justify-center'><Icons.Map className="w-6 mr-2" /> Endereço</div>
                </button>
                <button
                    className={`hidden ml-4 px-4 py-2 font-semibold text-gray-700 rounded-t-lg hover:bg-primary-300 hover:text-white ${activeTab === 'edit-password' ? 'text-white border-b-2 border-primary-300 bg-primary-300' : ''
                        }`}
                    onClick={() => setActiveTab('edit-password')}
                >
                    <div className='flex items-center justify-center'><Icons.Account className="w-6 mr-2" /> Editar senha</div>
                </button>
                <button
                    className={`ml-4 px-4 py-2 font-semibold text-gray-700 rounded-t-lg hover:bg-primary-300 hover:text-white ${activeTab === 'downloads' ? 'text-white border-b-2 border-primary-300 bg-primary-300' : ''
                        }`}
                    onClick={() => setActiveTab('downloads')}
                >
                    <div className='flex items-center justify-center'><Icons.Downloads className="w-6 mr-2" /> Downloads</div>
                </button>
            </div>

            <div className="p-4 bg-white border rounded-b-lg shadow-sm">
                {activeTab === 'edit-account' && <EditAccount />}
                {activeTab === 'edit-address' && <EditAddress />}
                {/* {activeTab === 'edit-password' && <ChangePassword />} */}
                {activeTab === 'downloads' && <DownloadPage />}
            </div>
        </div>
    );
};

export default MyAccount;
