import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Spinner from '../Components/Spinner';

const ProtectedRoute = ({ children, requiredLevel = 'user_lvl_02' }) => {
    const { user, loading } = useContext(UserContext);
    const location = useLocation();

    // Aguarde até que o carregamento termine antes de decidir sobre o redirecionamento
    if (loading) {
        return <Spinner />; // Mostra o spinner enquanto o contexto está carregando
    }

    // Se o usuário não está autenticado, redirecione para a página de login
    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Verifica se o objeto indicadores e nivel_assinatura estão definidos
    const userLevel = user.indicadores?.nivel_assinatura || 'user_lvl_01'; // Define 'user_lvl_01' como padrão se estiver indefinido

    // Se o nível do usuário for inferior ao nível necessário, redireciona para "Minha Conta"
    if (userLevel < requiredLevel) {
        return <Navigate to="/minha-conta" state={{ from: location }} replace />;
    }

    // Caso contrário, renderize o conteúdo protegido
    return children;
};

export default ProtectedRoute;
