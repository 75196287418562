import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [maioresAltas, setMaioresAltas] = useState([]);
    const [maioresBaixas, setMaioresBaixas] = useState([]);
    const [loadingAltas, setLoadingAltas] = useState(false);
    const [loadingBaixas, setLoadingBaixas] = useState(false);
    const [tickerData, setTickerData] = useState(null);
    const [loadingTicker, setLoadingTicker] = useState(false);
    const [tickerSymbol, setTickerSymbol] = useState(null);

    const fetchMaioresAltas = useCallback(async (page = 1) => {
        setLoadingAltas(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_OTHER_API_URL}/market_data/maiores_altas?page=${page}`);
            const data = response.data.data;
            setMaioresAltas((prevData) => (page === 1 ? data : [...prevData, ...data]));
        } catch (error) {
            console.error('Erro ao buscar maiores altas:', error);
        }
        setLoadingAltas(false);
    }, []);

    const fetchMaioresBaixas = useCallback(async (page = 1) => {
        setLoadingBaixas(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_OTHER_API_URL}/market_data/maiores_baixas?page=${page}`);
            const data = response.data.data;
            setMaioresBaixas((prevData) => (page === 1 ? data : [...prevData, ...data]));
        } catch (error) {
            console.error('Erro ao buscar maiores baixas:', error);
        }
        setLoadingBaixas(false);
    }, []);

    const fetchTickerData = useCallback(async (tickerSymbol) => {
        setLoadingTicker(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_OTHER_API_URL}/market_data/data/${tickerSymbol}`);
            const data = response.data;
            const latestData = data.reduce((latest, current) => {
                return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
            }, data[0]);
            setTickerData(latestData);
        } catch (error) {
            console.error('Erro ao buscar dados do ticker', error);
        }
        setLoadingTicker(false);
    }, []);

    useEffect(() => {
        fetchMaioresAltas();
        fetchMaioresBaixas();
    }, [fetchMaioresAltas, fetchMaioresBaixas]);

    return (
        <DataContext.Provider value={{
            maioresAltas,
            maioresBaixas,
            fetchMaioresAltas,
            fetchMaioresBaixas,
            loadingAltas,
            loadingBaixas,
            fetchTickerData,
            tickerData,
            loadingTicker,
            tickerSymbol,
            setTickerSymbol
        }}>
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;
