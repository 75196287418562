import React from 'react';

const CardPressao = ({ pressao_vendedor, pressao_comprador }) => {
    const formatPercentage = (value) => `${(value * 100).toFixed(2)}%`;

    const maiorPressao = pressao_vendedor > pressao_comprador ? 'Vendedor' : 'Comprador';

    return (
        <div className="card">
            <div className="flex items-center justify-between">
                <span className="card-label">Pressão</span>
                <span className="card-label">{maiorPressao}</span>
            </div>
            <div className="relative flex mt-1 overflow-hidden border rounded-full shadow-inner bg-green-50">
                <div
                    style={{ width: formatPercentage(pressao_comprador) }}
                    className="buy-bg range"
                >
                    {formatPercentage(pressao_comprador)}
                </div>
            </div>
            <div className="relative flex mt-2 overflow-hidden border rounded-full shadow-inner bg-red-50">
                <div
                    style={{ width: formatPercentage(pressao_vendedor) }}
                    className="sell-bg range"
                >
                    {formatPercentage(pressao_vendedor)}
                </div>
            </div>
        </div>
    );
};

export default CardPressao;
