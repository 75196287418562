import React, { useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';
import SearchBar from '../SearchBar/SearchBar';
import Icons from './Icons';
import Logo from '../../assets/images/fluxomta.webp';
import { UserContext } from '../../context/UserContext'; // Importa o contexto do usuário

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const { user } = useContext(UserContext); // Obtém as informações do usuário do contexto

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setIsOpen(false);
    };

    const handleSearch = () => {
        setIsOpen(false);
    };

    // Verifica se o usuário tem nível 2 ou maior
    const hasLevelTwoAccess = user && user.indicadores && user.indicadores.nivel_assinatura && user.indicadores.nivel_assinatura >= 'user_lvl_02';

    return (
        <>
            <nav className="fixed top-0 left-0 z-40 w-full bg-primary-500">
                <div className="container relative flex flex-wrap items-center justify-between py-4 mx-auto">
                    <div className="absolute flex items-center lg:hidden">
                        <button
                            type="button"
                            className="text-slate-500 hover:bg-slate-700 focus:outline-none focus:ring-1 focus:ring-slate-700 rounded-md text-sm p-2.5"
                            aria-controls="navbar-sticky"
                            aria-expanded="false"
                            onClick={toggleMenu}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Icons.Menu className="w-6" />
                        </button>
                    </div>
                    <Link to="/" className="flex justify-center w-full mx-auto">
                        <img src={Logo} className="h-8 " alt="Logo" />
                    </Link>

                    <div className='absolute right-0 hidden lg:block'>
                        {hasLevelTwoAccess && (
                            <SearchBar onSearch={handleSearch} />
                        )}
                    </div>
                </div>
                <div className={`fixed inset-0 z-50 flex items-center justify-start bg-black bg-opacity-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                    <div ref={menuRef} className={`relative w-full h-full bg-primary-500 shadow-lg transform transition-transform duration-300 ${isOpen ? '-translate-x-0' : '-translate-x-full'}`}>
                        <div className='flex justify-end'>
                            <button
                                type="button"
                                className="text-slate-500 hover:bg-slate-700 focus:outline-none focus:ring-1 focus:ring-slate-700 rounded-md text-sm p-2.5 m-4"
                                onClick={toggleMenu}
                            >
                                <span className="sr-only">Close menu</span>
                                <Icons.Close className="w-6" />
                            </button>
                        </div>
                        <ul className="flex flex-col p-4">
                            <li className='block py-2 border-b border-gray-700 lg:hidden '>
                                <UserMenu toggleMenu={toggleMenu} />
                            </li>
                            <li className='py-2 border-b border-gray-700'>
                                <Link to="/" className="flex py-2 pl-3 pr-4 font-semibold rounded text-slate-200 hover:text-slate-900 hover:bg-slate-100" onClick={handleLinkClick}>
                                    <span className='mr-2'><Icons.Home className="w-6" /></span>Home
                                </Link>
                            </li>

                            {/* Exibe esses itens de menu apenas para usuários com nível 2 ou superior */}
                            {hasLevelTwoAccess && (
                                <>
                                    <li className='py-2 border-b border-gray-700'>
                                        <Link to="/maiores-altas" className="flex py-2 pl-3 pr-4 font-semibold text-green-500 rounded hover:text-white hover:bg-green-900" onClick={handleLinkClick}>
                                            <span className='mr-2'><Icons.High className="w-6" /></span>Maiores Altas
                                        </Link>
                                    </li>
                                    <li className='py-2'>
                                        <Link to="/maiores-baixas" className="flex py-2 pl-3 pr-4 font-semibold text-red-500 rounded hover:text-white hover:bg-red-900" onClick={handleLinkClick}>
                                            <span className='mr-2'><Icons.Low className="w-6" /></span>Maiores Baixas
                                        </Link>
                                    </li>
                                    <li className='py-2'>
                                        <Link to="/comparador" className="flex py-2 pl-3 pr-4 font-semibold rounded text-secondary-500 hover:text-white hover:bg-secondary-600" onClick={handleLinkClick}>
                                            <span className='mr-2'><Icons.Tickers className="w-6" /></span>Comparador de Ativos
                                        </Link>
                                    </li>
                                </>
                            )}

                            <div className='relative mt-4'>
                                {hasLevelTwoAccess && (
                                    <SearchBar onSearch={handleSearch} />
                                )}
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="relative z-20 hidden w-full bg-primary-600 lg:block">
                    <div className="container relative flex flex-wrap items-center justify-between py-1 mx-auto">
                        <ul className="relative flex flex-row items-center w-full p-5 ">
                            {/* Exibe os itens no menu superior apenas se o usuário tiver nível 2 ou superior */}
                            {hasLevelTwoAccess && (
                                <>
                                    <li className=''>
                                        <Link to="/maiores-altas" className="flex items-center  text-sm font-semibold text-white rounded hover:text-green-500" onClick={handleLinkClick}>
                                            <span className='mr-2'><Icons.High className="w-4 text-green-500" /></span>Maiores Altas
                                        </Link>
                                    </li>
                                    <li className=''>
                                        <Link to="/maiores-baixas" className="flex items-center  text-sm font-semibold text-white rounded hover:text-red-500" onClick={handleLinkClick}>
                                            <span className='mr-2'><Icons.Low className="w-4 text-red-500" /></span>Maiores Baixas
                                        </Link>
                                    </li>
                                    <li className=''>
                                        <Link to="/comparador" className="flex items-center  text-sm font-semibold text-white rounded hover:text-secondary-500" onClick={handleLinkClick}>
                                            <span className='mr-2'><Icons.Tickers className="w-4 text-secondary-500" /></span>Comparador de Ativos
                                        </Link>
                                    </li>
                                </>
                            )}
                        </ul>
                        <div className='absolute right-0 w-4/12'>
                            <UserMenu toggleMenu={toggleMenu} />
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
