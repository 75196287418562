import React from 'react';
import CardHead from './CardHead';
import CardMacro from './CardMacro';
import CardPressao from './CardPressao';
import CardVies from './CardVies';
import CardSaldo from './CardSaldo';
import SaldoVariation from './SaldoVariation';
import CardFooter from './CardFooter';
import CardEficiencia from './CardEficiencia';


const Card = ({
    ticker,
    pressao_comprador,
    pressao_vendedor,
    pts_variation,
    range_negativo,
    range_positivo,
    balance,
    saldo_atual,
    timestamp,
    variation,
    vies,
    variation_saldo,
    eficiencia,
    lado_eficiencia
}) => {
    return (
        <div className="p-4 transition-shadow bg-white border rounded-lg shadow-sm ">
            <CardHead ticker={ticker} variation={variation} variationpts={pts_variation} />
            <div className='p-2 mt-4 border border-gray-200 rounded-md bg-gray-50'>
                <span className="pl-1 font-semibold card-label">Day Trade</span>
                <CardPressao pressao_comprador={pressao_comprador} pressao_vendedor={pressao_vendedor} />
                <CardSaldo saldo_atual={saldo_atual} />
                <SaldoVariation balance={balance} variation_saldo={variation_saldo} />
                <CardEficiencia eficiencia={eficiencia} eficiencia_lado={lado_eficiencia} />

            </div>
            <div className='p-2 mt-4 border border-gray-200 rounded-md bg-gray-50'>
                <span className="pl-1 font-semibold card-label">Longo Prazo</span>
                <CardMacro range_negativo={range_negativo} range_positivo={range_positivo} />
                <CardVies vies={vies} />
            </div>
            <CardFooter timestamp={timestamp} />
        </div>
    );
};

export default Card;
