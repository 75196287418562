import React from 'react';

const CardMacro = ({ range_positivo, range_negativo }) => {
    const maxPressure = Math.max(range_positivo, range_negativo);
    const maxLabel = range_positivo >= range_negativo ? 'buy-bg' : 'sell-bg';

    const formatPercentage = (value) => `${(value * 100).toFixed(2)}%`;

    return (
        <div className="card">
            <div className="flex items-center">
                <span className="card-label">Fluxo Macro</span>
            </div>
            <div className="relative flex mt-1 overflow-hidden border rounded-full shadow-inner bg-slate-100">
                <div style={{ width: formatPercentage(maxPressure) }} className={`${maxLabel} range`}>{formatPercentage(maxPressure)}</div>
            </div>
        </div>
    );
};

export default CardMacro;
