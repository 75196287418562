import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CardSkeleton = () => (
    <div className="p-4 transition-shadow bg-white border rounded-lg shadow-sm hover:shadow-lg">
        <div className="flex">
            <div className="w-full">
                <div className="flex justify-between border-b border-slate-200">
                    <Skeleton width={80} height={20} className='mb-1' />
                    <div className="flex justify-end">
                        <div className='flex'>
                            <Skeleton width={50} height={5} className="mr-2" />
                            <Skeleton width={50} height={5} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='p-2 mt-4 border border-gray-200 rounded-md bg-gray-50'>
            <span className="pl-1 font-semibold card-label">Day Trade</span>
            <div className="card h-[95px]">
                <div className="flex justify-between">
                    <span className="card-label">Pressão</span>
                    <Skeleton width={100} height={10} />
                </div>
                <div className="relative flex h-[22px] overflow-hidden -mt-1 border rounded-full shadow-inner bg-green-50">
                    <Skeleton width="100%" height={5} />
                </div>
                <div className="relative flex h-[22px] mt-2 overflow-hidden border rounded-full shadow-inner bg-red-50">
                    <Skeleton width="100%" height={5} />
                </div>
            </div>

            <div className="card h-[39px]">
                <div className="flex items-center justify-between">
                    <span className="mb-2 card-label">Saldo Atual</span>
                    <Skeleton width={50} height={15} />
                </div>
            </div>

            <div className="card">
                <div className="flex justify-between">
                    <span className="mt-1 card-label">Variação de Saldo <span className='text-xs font-normal'>(5min)</span></span>
                    <div className='flex'>
                        <Skeleton width={50} height={15} className="mr-1" />
                        <Skeleton width={50} height={15} />
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="flex items-center justify-between">
                    <span className="card-label">
                        <Skeleton width={100} height={15} />
                    </span>
                </div>
            </div>
        </div>
        <div className='p-2 mt-4 border border-gray-200 rounded-md bg-gray-50'>
            <span className="pl-1 font-semibold card-label">Longo Prazo</span>
            <div className="card">
                <div className="flex ">
                    <span className="mb-1 card-label">Fluxo Macro</span>
                </div>
                <div className="relative flex h-[22px] overflow-hidden border rounded-full shadow-inner bg-slate-100">
                    <Skeleton width="100%" height={5} />
                </div>
            </div>

            <div className="card">
                <div className="flex items-center justify-between">
                    <span className="card-label">
                        Viés<span className="block text-xs font-normal">(Médio Prazo)</span>
                    </span>
                    <Skeleton width={100} height={20} />
                </div>
            </div>
        </div>

        <div className="flex justify-end ">
            <Skeleton width={150} height={5} />
        </div>
    </div >
);

export default CardSkeleton;
