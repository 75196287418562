import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import DataContext from '../../context/DataContext';
import { Helmet } from 'react-helmet';

import { Card } from '../Card/Index';
import Icons from '../Icons';
import CardSkeleton from '../Card/CardSkeleton';

import Spinner from '../../Components/Spinner';


const Home = () => {
    const { maioresAltas = [], maioresBaixas = [], fetchMaioresAltas, fetchMaioresBaixas, loadingAltas = true, loadingBaixas = true } = useContext(DataContext);
    const { user, loading: userLoading } = useContext(UserContext);

    useEffect(() => {
        fetchMaioresAltas();
        fetchMaioresBaixas();
    }, [fetchMaioresAltas, fetchMaioresBaixas]);

    // Verificação para garantir que o conteúdo seja carregado mesmo se o usuário não estiver logado
    if (userLoading) {
        return <div><Spinner /></div>;
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <Helmet>
                <title>Painel de Indicadores - Fluxo MTA</title>
                <meta name="description" content="This is the home page" />
            </Helmet>

            <div className='mb-8'>
                <div className="flex items-center mb-4 text-xl font-bold text-green-700">
                    <span className='mr-2'>
                        <Icons.High className="w-6" />
                    </span>
                    <Link to="/maiores-altas" className="text-2xl" onClick={scrollToTop}>Maiores Altas</Link>
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                    {loadingAltas ? (
                        Array.from({ length: 8 }).map((_, index) => (
                            <CardSkeleton key={index} />
                        ))
                    ) : (
                        maioresAltas.map((data, index) => (
                            <Card key={index} {...data} user={user} /> // Passando user para o componente Card
                        ))
                    )}
                </div>
            </div>

            <div className='mb-16'>
                <div className="flex items-center mb-4 text-xl font-bold text-red-700">
                    <span className='mr-2'>
                        <Icons.Low className="w-6" />
                    </span>
                    <Link to="/maiores-baixas" className="text-2xl" onClick={scrollToTop}>Maiores Baixas</Link>
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                    {loadingBaixas ? (
                        Array.from({ length: 8 }).map((_, index) => (
                            <CardSkeleton key={index} />
                        ))
                    ) : (
                        maioresBaixas.map((data, index) => (
                            <Card key={index} {...data} user={user} /> // Passando user para o componente Card
                        ))
                    )}
                </div>
            </div>
        </>
    );
};

export default Home;
