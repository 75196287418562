import React from 'react';

const CardVies = ({ vies }) => {
    const getViesLabel = (vies) => {
        switch (parseInt(vies, 10)) {
            case 2:
                return 'Comprador';
            case 1:
                return 'Compra Neutra';
            case -1:
                return 'Venda Neutra';
            case -2:
                return 'Vendedor';
            default:
                return 'N/A';
        }
    };

    const getViesClass = (vies) => {
        switch (parseInt(vies, 10)) {
            case 2:
            case 1:
                return 'buy';
            case -1:
            case -2:
                return 'sell';
            default:
                return 'neutral';
        }
    };

    return (
        <div className={`${getViesClass(vies)}-bg-light card`}>
            <div className="flex items-center justify-between">
                <span className="card-label">
                    Viés<span className="block text-xs font-normal">(Médio Prazo)</span>
                </span>
                <span className={`card-label ${getViesClass(vies)}-text`}>{getViesLabel(vies)}</span>
            </div>
        </div>
    );
};

export default CardVies;
