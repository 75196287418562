import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../../../assets/images/fluxomta.webp'
import SpinnerSmall from '../../SpinnerSmall';

function StepOferta({ data }) {
    const [productName, setProductName] = useState('');
    const [loading, setLoading] = useState(true);

    // Determinar o ID do produto com base nas respostas
    let productId = 1147; // Default link01

    if (data.step1 === 'option2' && data.step2 === 'option2' && data.step3 === 'option2' && data.step4 === 'option2' && data.step5 === 'option2') {
        productId = 1149; // link02
    } else if (data.step1 === 'option3' && data.step2 === 'option3' && data.step3 === 'option3' && data.step4 === 'option3' && data.step5 === 'option3') {
        productId = 1075; // link03
    } else if (data.step1 === 'option4' || data.step2 === 'option4' || data.step3 === 'option4' || data.step4 === 'option4' || data.step5 === 'option4') {
        productId = 1150; // link04
    }

    // Gerar o link de checkout
    const checkoutUrl = `https://assine.fluxomta.com/checkout/?add-to-cart=${productId}`;

    useEffect(() => {
        // Função para buscar o nome do produto
        const fetchProductName = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://assine.fluxomta.com/wp-json/wc/v3/products/${productId}`, {
                    params: {
                        consumer_key: process.env.REACT_APP_CONSUMER_KEY,
                        consumer_secret: process.env.REACT_APP_CONSUMER_SECRET,
                    },
                });
                setProductName(response.data.name); // Supondo que o nome do produto está na chave 'name'
            } catch (error) {
                console.error('Erro ao buscar o nome do produto:', error);
                setProductName('Produto não encontrado');
            } finally {
                setLoading(false);
            }
        };

        fetchProductName();
    }, [productId]);

    return (
        <div className="flex items-center justify-center text-white">
            <div className="max-w-2xl">
                <img src={logo} alt="Fluxo Mta Oferta Especial" className='mx-auto mb-4' />
                <h2 className="text-3xl font-bold text-center">Oferta</h2>
                <div className="relative space-y-4">
                    {loading ? (
                        <p className="font-medium text-secondary-500"><SpinnerSmall /></p>
                    ) : (
                        <>
                            <p className="text-2xl font-bold text-center text-secondary-500">{productName}</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet libero rutrum, euismod eros id, accumsan ex. Aliquam ac laoreet urna. Curabitur congue ornare neque, eu finibus augue lobortis vestibulum. Maecenas convallis dolor non nibh volutpat luctus.</p>
                            <div className="flex gap-4">
                                <a href={checkoutUrl} className="flex items-center justify-center w-full px-4 py-4 text-xl font-bold tracking-wide text-white uppercase duration-200 transform rounded-md bg-secondary-500 hover:bg-secondary-400 focus:outline-none focus:bg-secondary-400 focus:ring-1 focus:ring-primary-200 focus:ring-opacity-50">
                                    Finalizar Compra
                                </a>
                                <a href={'#'} className="flex items-center justify-center w-full px-4 py-4 font-bold tracking-wide text-center text-white uppercase duration-200 transform rounded-md text-md bg-primary-400 hover:bg-secondary-400 focus:outline-none focus:bg-secondary-400 focus:ring-1 focus:ring-primary-200 focus:ring-opacity-50">
                                    Ainda em duvida? Saiba Mais
                                </a>
                            </div>
                        </>
                    )}


                </div>
            </div>
        </div>
    );
}

export default StepOferta;
