import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import Icons from '../../Components/Icons';
import { Card } from '../../Components/Card/Index';
import SearchTicker from '../../Components/SearchBar/SearchTicker';
import Spinner from '../../Components/Spinner';

const CompareTickers = () => {
    const [tickers, setTickers] = useState([]);
    const [tickerData, setTickerData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const initialTickers = searchParams.get('tickers') || localStorage.getItem('tickers');
        if (initialTickers) {
            const tickersArray = initialTickers.split(',');
            setTickers(tickersArray);
            fetchData(tickersArray);
        }
    }, []);

    const updateURL = (tickers) => {
        setSearchParams({ tickers: tickers.join(',') });
        localStorage.setItem('tickers', tickers.join(','));
    };

    const handleAddTicker = (ticker) => {
        if (ticker && !tickers.includes(ticker.toUpperCase())) {
            if (tickers.length < 4) {
                const newTickers = [...tickers, ticker.toUpperCase()];
                setTickers(newTickers);
                setError('');
                updateURL(newTickers);
            } else {
                setError('Você só pode comparar até 4 tickers.');
            }
        }
    };

    const handleRemoveTicker = (ticker) => {
        const newTickers = tickers.filter(t => t !== ticker);
        setTickers(newTickers);
        setError('');
        updateURL(newTickers);
    };

    const handleClearAll = () => {
        setTickers([]);
        setTickerData([]);
        setError('');
        setSearchParams({});
        localStorage.removeItem('tickers');
    };

    const fetchData = async (tickers) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_OTHER_API_URL}/market_data/compare?tickers=${tickers.join(',')}`);
            setTickerData(response.data);
        } catch (error) {
            console.error('Erro ao buscar dados dos tickers', error);
        }
        setLoading(false);
    };

    const handleSearch = async () => {
        if (tickers.length > 0) {
            fetchData(tickers);
        }
    };

    return (
        <>
            <div className="mb-4">
                <div className='grid w-full grid-cols-1 gap-4 p-4 bg-white border border-gray-200 rounded-lg md:grid-cols-2 lg:grid-cols-4'>
                    <div className='flex flex-col justify-start w-full col-span-1 gap-2 lg:flex-row lg:items-center lg:col-span-2'>
                        <div className='w-full lg:flex '>
                            <SearchTicker onSearch={(ticker) => handleAddTicker(ticker)} />
                            {error && <div className="text-sm font-semibold text-red-500 error-message">{error}</div>}
                        </div>
                    </div>
                    <div className='flex flex-col justify-end col-span-1 gap-2 lg:flex-row lg:items-center lg:col-span-2'>
                        <div className='flex flex-wrap justify-start w-full gap-1 lg:w-auto'>
                            {tickers.map(ticker => (
                                <span key={ticker} className="flex items-center justify-between p-2 text-xs font-semibold border rounded-lg text-primary-500 hover:bg-primary-50 lg:w-auto ticker-tag">
                                    {ticker} <button onClick={() => handleRemoveTicker(ticker)}><Icons.Trash className="w-4 ml-1 text-red-500 " /></button>
                                </span>
                            ))}
                        </div>
                        <button className="btn-submit !w-auto" onClick={handleSearch}>Comparar Ativos</button>
                    </div>
                </div>
            </div>
            {loading ? (
                <div><Spinner /></div>
            ) : (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                    {tickerData.map((data, index) => (
                        <Card key={index} {...data} />
                    ))}
                </div>
            )}
            <div className='flex items-center justify-between w-full gap-4 p-4 mt-4 bg-white border border-gray-300 rounded-lg md:grid-cols-2 lg:grid-cols-4'>
                <p>Dados atualizados a cada minuto. Para visualizar recarregue a pagina.</p>
                <button className="flex items-center w-full p-2 text-sm font-semibold text-white transition-all bg-red-500 rounded-md lg:w-auto hover:text-white hover:bg-red-700" onClick={handleClearAll}>Limpar Lista<Icons.Trash className="w-4 ml-2" /></button>
            </div>
        </>
    );
};

export default CompareTickers;
