import React from 'react';

const CardEficiencia = ({ eficiencia, eficiencia_lado }) => {
    const getEficienciaText = (eficiencia, eficiencia_lado) => {
        const eficienciaLabel = eficiencia < 1.00 ? 'Não direcional' : 'Direcional';
        const eficienciaLadoLabel = eficiencia_lado === 'Eficiência Vendedora' ? 'Vendedora' : 'Compradora';
        if (eficienciaLabel === 'Não direcional') {
            return `Eficiência ${eficienciaLabel}`;
        }
        return `Eficiência ${eficienciaLabel} ${eficienciaLadoLabel}`;
    };

    const eficienciaClass = eficiencia < 1.00 ? 'neutral' : (eficiencia_lado === 'Eficiência Vendedora' ? 'sell' : 'buy');

    return (
        <div className={`${eficienciaClass}-bg-light card `}>
            <div className="flex items-center justify-between">
                <span className={`card-label ${eficienciaClass}-text`}>
                    {eficiencia !== undefined && eficiencia !== null
                        ? getEficienciaText(eficiencia, eficiencia_lado)
                        : 'Dados indisponíveis'}
                </span>
            </div>
        </div>
    );
};

export default CardEficiencia;
