import React from 'react';

const CardFooter = ({ timestamp }) => {
    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };

    return (
        <div className="flex justify-end mt-2">
            <p className="text-xs text-slate-500"><strong>Última Atualização:</strong> {formatDateTime(timestamp)}</p>
        </div>
    );
};

export default CardFooter;

