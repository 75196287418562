import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { UserContext } from '../../context/UserContext';

const Register = () => {
    const { setUser } = useContext(UserContext);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_CUSTOM_API_URL}/v1/register`, {
                username,
                email,
                password,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const token = response.data.token;

            // Armazene o token JWT no localStorage
            localStorage.setItem('token', token);

            // Decodifique o token JWT
            const decoded = jwtDecode(token);

            // Atualize o usuário no contexto
            setUser(decoded.data.user);

            setMessage('Registro realizado com sucesso!');
            navigate('/');
        } catch (error) {
            setMessage('Erro ao registrar. Por favor, tente novamente.');
            console.error('Erro ao registrar:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md">
            <h2 className="mb-6 text-2xl font-bold">Registrar</h2>
            {message && <div className="mb-4 text-red-500">{message}</div>}
            <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700">Nome de Usuário</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700">Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-6">
                <label className="block mb-2 text-sm font-bold text-gray-700">Senha</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 mb-3 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
            </div>
            <button type="submit" className="px-4 py-2 text-white bg-blue-500 rounded focus:outline-none focus:shadow-outline" disabled={loading}>
                {loading ? 'Carregando...' : 'Registrar'}
            </button>
        </form>
    );
};

export default Register;
