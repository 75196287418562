import React from 'react';

const SaldoVariation = ({ balance, variation_saldo }) => {
    const formatCurrency = (value) => {
        if (isNaN(value) || value === null) return 'R$ 0,00';

        const absValue = Math.abs(value);
        const isNegative = value < 0;
        let formattedValue;

        if (absValue >= 1e9) {
            formattedValue = `${(absValue / 1e9).toFixed(2).replace('.', ',')}<span className="text-xs ml-0.5">Bi</span>`;
        } else if (absValue >= 1e6) {
            formattedValue = `${(absValue / 1e6).toFixed(2).replace('.', ',')}<span className="text-xs ml-0.5">Mi</span>`;
        } else if (absValue >= 1e3) {
            formattedValue = `${(absValue / 1e3).toFixed(0).replace('.', ',')}<span className="text-xs ml-0.5">Mil</span>`;
        } else {
            formattedValue = absValue.toFixed(2).replace('.', ',');
        }

        return `${isNegative ? '-' : ''}${formattedValue}`;
    };

    const saldoClass = balance < 0 ? 'sell' : 'buy';
    const variationClass = variation_saldo < 0 ? 'sell' : (variation_saldo > 0 ? 'buy' : 'gray');

    return (
        <div className="card">
            <div className="flex items-center justify-between">
                <span className="card-label">Variação de Saldo <span className='text-xs font-normal'>(5min)</span></span>
                <div>
                    <span className={`mr-1 card-label ${saldoClass}-text`} dangerouslySetInnerHTML={{ __html: formatCurrency(balance || 0) }}></span>
                    <span className={`card-label ${variationClass}-text`}>
                        <small>({variation_saldo !== null && variation_saldo !== undefined ? variation_saldo.toFixed(2).replace('.', ',') : '0,00'}%)</small>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SaldoVariation;
