import React from 'react';
import { Link } from 'react-router-dom';
import Register from '../../Components/Auth/Register';
import Logo from '../../assets/images/fluxomta.webp';


const RegisterPage = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-primary-500">
            <div className="flex justify-center w-full max-w-sm m-8 mx-auto lg:max-w-4xl">
                <div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
                    <div className="flex justify-center mx-auto">
                        <img className="w-auto h-7 sm:h-8" src={Logo} alt="Logo" />
                    </div>

                    <p className="mt-3 text-xl text-center text-gray-600 dark:text-gray-200">
                        Criar Conta
                    </p>

                    <div className="mt-4">
                        <Register />
                    </div>

                    <div className="flex items-center justify-between mt-4">
                        <span className="w-1/5 border-b dark:border-gray-600 lg:w-1/4"></span>
                        <Link
                            to="/login"
                            className="text-xs text-center text-gray-500 uppercase dark:text-gray-400 hover:underline"
                        >
                            Já tem conta? Faça o Login
                        </Link>


                        <span className="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
