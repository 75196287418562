import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';

const DashboardPage = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        // Limpar os dados do usuário no contexto
        setUser(null);
        // Remover o token JWT do local storage
        localStorage.removeItem('token');
        // Redirecionar para a página de login
        navigate('/login');
    };

    const handleSubscribe = () => {
        navigate('/assine');
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-lg p-6 bg-white rounded shadow-md">
                <p className="mb-4 text-gray-700">Aqui está o seu dashboard.</p>

                {user?.has_active_subscription ? (
                    <ul className="mb-4 text-left list-disc list-inside">
                        <li>Próxima expiração: {user.next_expiration_date}</li>
                        <li>Nível de assinatura: {user.nivel_assinatura}</li>
                        <li>Speed Flow: {user.speed_flow}</li>
                        <li>Liquidity Tracker: {user.liquidity_tracker}</li>
                        <li>Master Flow: {user.master_flow}</li>
                        <li>Target Vision: {user.target_vision}</li>
                    </ul>
                ) : (
                    <div className="mb-4 text-center">
                        <p className="text-red-500">Você não tem uma assinatura ativa.</p>
                        <button
                            onClick={handleSubscribe}
                            className="px-4 py-2 mt-4 text-white bg-blue-500 rounded focus:outline-none focus:shadow-outline"
                        >
                            Assine Agora
                        </button>
                    </div>
                )}

                <button
                    onClick={handleLogout}
                    className="px-4 py-2 mt-4 text-white bg-red-500 rounded focus:outline-none focus:shadow-outline"
                >
                    Sair
                </button>
            </div>
        </div>
    );
};

export default DashboardPage;
