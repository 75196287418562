import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const ConsolidatedSaldoChart = ({ tickerSymbol }) => {
    const [chartData, setChartData] = useState({
        series: [
            {
                name: 'Saldo Consolidado',
                data: []
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 500,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '95%',
                    endingShape: 'rounded',
                    colors: {
                        ranges: [{
                            from: -Infinity,
                            to: 50,
                            color: '#FF4560'
                        }]
                    }
                }
            },
            colors: ['#f9c600'],
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: [],
                labels: {
                    formatter: function (val) {
                        const date = new Date(val);
                        // Formata a data no formato 'dd/mm/yy - HH:MM'
                        return date.toLocaleDateString('pt-BR') + ' - ' + date.toLocaleTimeString('pt-BR', {
                            hour: '2-digit',
                            minute: '2-digit'
                        });
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return formatNumber(val);
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return formatNumber(val);
                    }
                }
            },
            legend: {
                show: false,
            }
        }
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!tickerSymbol) {
                console.error('tickerSymbol is null');
                return;
            }

            try {
                const response = await fetch(`https://api.server.fluxomta.com/market_data/consolidated_saldo/${tickerSymbol}`);
                const data = await response.json();

                if (!data || !Array.isArray(data.consolidated_saldo)) {
                    console.error('Invalid data format or undefined data:', data);
                    return;
                }

                const consolidatedSaldo = data.consolidated_saldo
                    .map(item => ({
                        x: new Date(item.hour).getTime(),  // Convertendo a hora para timestamp
                        y: item.consolidated_saldo || 0
                    }))
                    .sort((a, b) => a.x - b.x); // Ordenando do mais antigo para o mais recente

                const dates = consolidatedSaldo.map(item => item.x);

                setChartData(prevChartData => ({
                    series: [
                        {
                            name: 'Saldo Consolidado',
                            data: consolidatedSaldo.map(item => item.y)
                        }
                    ],
                    options: {
                        ...prevChartData.options,
                        xaxis: {
                            ...prevChartData.options.xaxis,
                            categories: dates
                        }
                    }
                }));

                setLoading(false);

            } catch (error) {
                console.error('Error fetching or processing data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [tickerSymbol]);

    const formatNumber = (num) => {
        const absNum = Math.abs(num);

        if (absNum >= 1.0e+12) {
            return (num / 1.0e+12).toFixed(2) + " Tri"; // Trilhão
        } else if (absNum >= 1.0e+9) {
            return (num / 1.0e+9).toFixed(2) + " Bi"; // Bilhão
        } else if (absNum >= 1.0e+6) {
            return (num / 1.0e+6).toFixed(2) + " Mi"; // Milhão
        } else if (absNum >= 1.0e+3) {
            return (num / 1.0e+3).toFixed(2) + " mil"; // Milhar
        } else {
            return num.toFixed(2); // Menor que mil
        }
    };

    return (
        <div>
            <div className={`card`}>
                <div className="flex items-center justify-between">
                    <span className="card-label">
                        Saldo Consolidado por hora<span className="block text-xs font-normal">(últimos 15 pregões)</span>
                    </span>
                </div>
            </div>
            {loading ? (
                <p>Carregando...</p>
            ) : (
                <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height={chartData.options.chart.height}
                />
            )}
        </div>
    );
};

export default ConsolidatedSaldoChart;
