import React, { useState } from 'react';

function Step4({ nextStep, prevStep, handleChange, data }) {
    const [selectedOption, setSelectedOption] = useState(data);

    const handleSelection = (value) => {
        setSelectedOption(value);
        handleChange('step1', value);
    };
    return (
        <div>
            <h2 className="text-2xl font-bold text-secondary-500">Pergunta 04?</h2>
            <p className="mt-2 mb-8 text-gray-100">Linha fina para auxiliar raciocínio</p>
            <div className="space-y-4">
                <label
                    className={`flex items-center justify-between p-4 border rounded-lg cursor-pointer ${selectedOption === 'option1' ? 'bg-primary-400' : 'bg-primary-700'
                        }`}
                    onClick={() => handleSelection('option1')}
                >                     <div className="flex items-center">
                        <input
                            type="radio"
                            name="step4"
                            value="option1"
                            className="w-5 h-5 form-radio text-secondary-500"
                            checked={data === 'option1'}
                            onChange={(e) => handleChange('step4', e.target.value)}
                        />
                        <span className="ml-4 font-medium text-secondary-500">Opção 1</span>
                    </div>
                </label>
                <label
                    className={`flex items-center justify-between p-4 border rounded-lg cursor-pointer ${selectedOption === 'option2' ? 'bg-primary-400' : 'bg-primary-700'
                        }`}
                    onClick={() => handleSelection('option2')}
                >                     <div className="flex items-center">
                        <input
                            type="radio"
                            name="step4"
                            value="option2"
                            className="w-5 h-5 form-radio text-secondary-500"
                            checked={data === 'option2'}
                            onChange={(e) => handleChange('step4', e.target.value)}
                        />
                        <span className="ml-4 font-medium text-secondary-500">Opção 2</span>
                    </div>
                </label>
                <label
                    className={`flex items-center justify-between p-4 border rounded-lg cursor-pointer ${selectedOption === 'option3' ? 'bg-primary-400' : 'bg-primary-700'
                        }`}
                    onClick={() => handleSelection('option3')}
                >                     <div className="flex items-center">
                        <input
                            type="radio"
                            name="step4"
                            value="option3"
                            className="w-5 h-5 form-radio text-secondary-500"
                            checked={data === 'option3'}
                            onChange={(e) => handleChange('step4', e.target.value)}
                        />
                        <span className="ml-4 font-medium text-secondary-500">Opção 3</span>
                    </div>
                </label>
                <label
                    className={`flex items-center justify-between p-4 border rounded-lg cursor-pointer ${selectedOption === 'option4' ? 'bg-primary-400' : 'bg-primary-700'
                        }`}
                    onClick={() => handleSelection('option4')}
                >                     <div className="flex items-center">
                        <input
                            type="radio"
                            name="step4"
                            value="option4"
                            className="w-5 h-5 form-radio text-secondary-500"
                            checked={data === 'option4'}
                            onChange={(e) => handleChange('step4', e.target.value)}
                        />
                        <span className="ml-4 font-medium text-secondary-500">Opção 4</span>
                    </div>
                </label>
            </div>
            <div className="flex justify-between mt-8">
                {prevStep && (
                    <button onClick={prevStep} className="text-gray-500">Voltar</button>
                )}
                <button onClick={nextStep} className="px-6 py-2 font-semibold text-white rounded-lg bg-secondary-600">Avançar</button>
            </div>
        </div>
    );
}

export default Step4;
