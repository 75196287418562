import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_CUSTOM_API_URL}/v1/user-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const userData = response.data;
      setUser(userData);
      console.log('Usuário definido no contexto:', userData);
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error);
      setUser(null);
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  };

  const initializeUser = () => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData(token);
    } else {
      setUser(null);
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  useEffect(() => {
    initializeUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, logout, loading }}>
      {children}
    </UserContext.Provider>
  );
};
