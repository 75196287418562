import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Icons from '../Icons';

const SearchTicker = ({ onSearch }) => {
    const [query, setQuery] = useState('');
    const [tickers, setTickers] = useState([]);
    const [filteredTickers, setFilteredTickers] = useState([]);
    const [activeIndex, setActiveIndex] = useState(-1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_OTHER_API_URL}/market_data/ticker_variations`);
                setTickers(response.data);
            } catch (error) {
                console.error('Erro ao buscar dados da API', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const results = tickers.filter(ticker =>
            ticker.ticker.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredTickers(results);
    }, [query, tickers]);

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setActiveIndex((prevIndex) => (prevIndex + 1) % filteredTickers.length);
        } else if (e.key === 'ArrowUp') {
            setActiveIndex((prevIndex) => (prevIndex - 1 + filteredTickers.length) % filteredTickers.length);
        } else if (e.key === 'Enter' && activeIndex >= 0) {
            const selectedTicker = filteredTickers[activeIndex].ticker;
            setQuery('');
            setFilteredTickers([]);
            setActiveIndex(-1);
            if (onSearch) onSearch(selectedTicker); // Apenas chama onSearch com o ticker selecionado
        } else if (e.key === 'Escape') {
            setQuery('');
            setFilteredTickers([]);
            setActiveIndex(-1);
        }
    };

    const handleItemClick = (ticker) => {
        setQuery('');
        setFilteredTickers([]);
        setActiveIndex(-1);
        if (onSearch) onSearch(ticker);
    };

    return (
        <>
            <form className="relative z-10">
                <label htmlFor="default-search" className="mb-2 text-sm font-medium sr-only text-slate-900">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 z-10 flex items-center pointer-events-none start-0 ps-3">
                        <Icons.Search className="w-4 text-slate-200" />
                    </div>
                    <input
                        type="text"
                        placeholder="Buscar ticker"
                        value={query}
                        onChange={(e) => {
                            setQuery(e.target.value);
                            setActiveIndex(-1);
                        }}
                        onKeyDown={handleKeyDown}
                        className="block w-full p-1.5 outline-none text-sm rounded-md text-white  font-semibold ps-10  bg-primary-500"
                    />
                    {query && (
                        <div className="absolute left-0 right-0 z-10 w-full overflow-y-scroll text-white rounded-md rounded-t-none shadow-lg max-h-96 bg-primary-500">
                            {filteredTickers.map((ticker, index) => {
                                const variationClass = ticker.variation >= 0 ? 'buy-text' : 'sell-text';
                                return (
                                    <div
                                        key={index}
                                        onClick={() => handleItemClick(ticker.ticker)}
                                        className={`px-4 flex justify-between hover:bg-[#000] hover:bg-opacity-80 cursor-pointer py-1.5 text-slate-100 ${index === activeIndex ? 'bg-[#444444] text-white' : ''}`}
                                    >
                                        {ticker.ticker} <span className={`${variationClass} font-semibold`}>({ticker.variation.toFixed(2)}%)</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </form>
        </>
    );
};

export default SearchTicker;
