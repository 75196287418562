import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

import Sidebar from './Sidebar';
import StepOferta from './StepOferta';

function MultiStepForm() {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        step1: '',
        step2: '',
        step3: '',
        step4: '',
        step5: ''
    });
    const [showOferta, setShowOferta] = useState(false);

    const nextStep = () => setCurrentStep(prevStep => prevStep + 1);
    const prevStep = () => setCurrentStep(prevStep => prevStep - 1);

    const handleChange = (step, value) => {
        setFormData(prevData => ({
            ...prevData,
            [step]: value
        }));
    };

    const goToOferta = () => {
        setShowOferta(true);
    };


    const renderStep = () => {
        if (showOferta) {
            return <StepOferta data={formData} />;
        }

        switch (currentStep) {
            case 1:
                return <Step1 nextStep={nextStep} handleChange={handleChange} data={formData.step1} />;
            case 2:
                return <Step2 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} data={formData.step2} />;
            case 3:
                return <Step3 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} data={formData.step3} />;
            case 4:
                return <Step4 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} data={formData.step4} />;
            case 5:
                return <Step5 prevStep={prevStep} handleChange={handleChange} data={formData.step5} goToOferta={goToOferta} />;
            default:
                return <Step1 nextStep={nextStep} handleChange={handleChange} data={formData.step1} />;
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-200">
            <div className="flex w-full max-w-4xl rounded-lg shadow-lg bg-primary-500">
                {/* Sidebar */}
                {!showOferta && <Sidebar currentStep={currentStep} />}

                {/* Content */}
                <div className={`${showOferta ? 'w-full' : 'w-3/4'} p-8`}>
                    {renderStep()}
                </div>
            </div>
        </div>
    );
}

export default MultiStepForm;
