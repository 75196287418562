import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import Icons from '../../Components/Menu/Icons';

const Downloads = () => {
    const { user } = useContext(UserContext);

    useEffect(() => {
        console.log('User data:', user);
    }, [user]);

    if (!user || !user.indicadores) {
        return <p>Carregando...</p>;
    }

    const productMapping = {
        sku_00: { name: 'Indicador ZD', file: '/downloads/ZD.zip' },
        sku_03: { name: ' MacroFlow' },
        sku_04: { name: 'Indicador Fluxo V6', file: '/downloads/fluxo.zip' },
        sku_05: { name: 'Indicador ALVO R3', file: '/downloads/alvo.zip' },
        sku_06: { name: 'Indicador Quantum LT', file: '/downloads/quantum.zip' },
        sku_07: { name: 'Pacote de 4 Indicadores', skus: ['sku_04', 'sku_05', 'sku_06', 'sku_00'] },
        community: { name: 'Comunidade Telegram', link: 'https://t.me/tonydaytrade' },
        whatsapp: { name: 'Comunidade Whatsapp', link: 'https://chat.whatsapp.com/LTDUnGNjhESEihKieyLXgu' },
    };

    const hasAccessToZD = true;
    const activeProducts = [];

    // Usuário tem acesso automático ao Indicador ZD (sku_00)
    if (hasAccessToZD) {
        activeProducts.push('sku_00');
    }

    // Se o usuário tem acesso ao pacote (SKU_07), adiciona os SKUs relevantes
    if (user.indicadores.sku_07 === "true") {
        productMapping.sku_07.skus.forEach(sku => {
            if (!activeProducts.includes(sku)) {
                activeProducts.push(sku);
            }
        });
    }

    // Se o usuário tem acesso ao SKU_01, adiciona os SKUs relevantes (similar ao SKU_07)
    if (user.indicadores.sku_01 === "true") {
        ['sku_03', 'sku_04', 'sku_05', 'sku_06'].forEach(sku => {
            if (!activeProducts.includes(sku)) {
                activeProducts.push(sku);
            }
        });
        activeProducts.push('community');
        activeProducts.push('whatsapp');
    }

    // Verifica individualmente outros produtos baseados nos indicadores do usuário
    Object.keys(user.indicadores).forEach((sku) => {
        if (user.indicadores[sku] === "true" && sku !== 'sku_07' && !activeProducts.includes(sku)) {
            activeProducts.push(sku);
        }
    });

    console.log('Active products:', activeProducts);

    return (
        <div className="p-6 bg-white rounded">
            <div className='flex justify-between items-center mb-4'>
                <h2 className="flex items-center text-2xl font-bold">
                    <Icons.Downloads className="w-6 mr-2" />
                    Downloads Disponíveis
                </h2>
            </div>

            {activeProducts.length > 0 ? (
                activeProducts.map((sku) => {
                    const product = productMapping[sku];

                    // Verifica se o produto existe no mapeamento antes de tentar acessá-lo
                    if (!product) {
                        return null;  // Pula produtos que não existem no mapeamento
                    }

                    return sku === 'whatsapp' ? (
                        <div key={sku} className="flex items-center p-4 bg-[#25D366] text-white rounded mb-2">
                            <a
                                href={product.link}
                                className="text-white hover:underline flex"
                            >
                                <Icons.DownloadIc className="w-6 mr-2" /> Acessar {product.name}
                            </a>
                        </div>
                    ) : sku === 'community' ? (
                        <div key={sku} className="flex items-center p-4 bg-[#2AABEE] text-white rounded mb-2">
                            <a
                                href={product.link}
                                className="text-white hover:underline flex"
                            >
                                <Icons.DownloadIc className="w-6 mr-2" /> Acessar {product.name}
                            </a>
                        </div>
                    ) : product.file ? (
                        <div key={sku} className="flex items-center p-4 bg-gray-100 rounded mb-2">
                            <a
                                href={product.file}
                                download
                                className="text-blue-600 hover:underline flex"
                            >
                                <Icons.DownloadIc className="w-6 mr-2" /> Download {product.name}
                            </a>
                        </div>
                    ) : (
                        <div key={sku} className="p-4 bg-gray-100 rounded text-blue-600 mb-2">
                            Acesso ao <strong>{product.name}</strong> está ativo. Aproveite todos os benefícios!
                        </div>
                    );
                })
            ) : (
                <p className="text-red-600">Você não tem acesso a nenhum download no momento.</p>
            )}

            <div className='py-4 text-primary-400 text-sm leading-7'>
                <p>Termos e Condições de Uso <br /> <strong> Por favor, leia com atenção!</strong> 💡</p>
                <p>Conforme o Artigo 38 da Lei nº 8078, devido à natureza de consumo imediato deste infoproduto — entregue 100% para download antes do prazo de 7 dias de garantia — é importante que você entenda as seguintes condições:</p>
                <p>Ao realizar o download do material, você renuncia automaticamente ao direito de solicitar reembolso, uma vez que o produto seja consumível e não possa ser devolvido após o acesso.</p>
                <p>Desta forma, ao clicar no link de download, o produto é considerado adquirido e não poderá ser devolvido.</p>
                <p><strong>Portanto, ao acessar o material, esteja plenamente consciente e de acordo com todas essas condições.</strong></p>
            </div>
        </div>
    );
};

export default Downloads;
