import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import { Helmet } from 'react-helmet';

import SaldoChart from '../../Components/Charts/SaldoChart';
import EficienciaChart from '../../Components/Charts/EficienciaChart';
import CardTicker from '../../Components/Card/CardTicker';
import ConsolidatedSaldoChart from '../../Components/Charts/ConsolidatedSaldoChart';

const TickerPage = () => {
    const { tickerSymbol: paramTickerSymbol } = useParams();
    const { fetchTickerData, tickerData, loadingTicker, setTickerSymbol } = useContext(DataContext);

    useEffect(() => {
        if (paramTickerSymbol) {
            setTickerSymbol(paramTickerSymbol);
            fetchTickerData(paramTickerSymbol);
        }
    }, [fetchTickerData, paramTickerSymbol, setTickerSymbol]);

    const safeToFixed = (value, decimals) => {
        return value !== undefined && value !== null ? value.toFixed(decimals) : '0.00';
    };

    // Extrair ticker e variation do tickerData quando estiver disponível
    const { ticker, variation } = tickerData || {};

    return (
        <>
            <Helmet>
                <title>{ticker ? `${ticker} - (${safeToFixed(variation, 2)}%) - Fluxo MTA` : 'Carregando...'}</title>
                <meta name="description" content={ticker ? `Detalhes sobre o ticker ${ticker} com variação ${safeToFixed(variation, 2)}%` : 'Carregando dados do ticker'} />
            </Helmet>

            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                <div className='relative grid w-full grid-cols-1 col-span-3 gap-4 p-4 pb-12 transition-shadow bg-white border rounded-lg shadow-sm lg:grid-cols-3 '>
                    <div className='col-span-3'>
                        {loadingTicker ? (
                            'Carregando'
                        ) : (
                            <CardTicker {...tickerData} />
                        )}
                    </div>

                    <div className='grid grid-cols-2 col-span-3 gap-4'>
                        <div className='col-span-1 p-2 border border-gray-200 rounded-md bg-gray-50'>
                            <SaldoChart tickerSymbol={paramTickerSymbol} />
                        </div>
                        <div className='col-span-1 p-2 border border-gray-200 rounded-md bg-gray-50'>
                            <EficienciaChart tickerSymbol={paramTickerSymbol} />
                        </div>
                    </div>
                    <div className='col-span-3'>
                        <div className='p-2 border border-gray-200 rounded-md bg-gray-50'>
                            <ConsolidatedSaldoChart tickerSymbol={paramTickerSymbol} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default TickerPage;
